.close-button {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;

    .triangle-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .icon {
        position: relative;
        z-index: 1;
        margin: 0;
    }
}
