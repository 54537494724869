.btn {
    border-radius: 10px;
    &.btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
    }

    &.btn-disabled {
        background-color: $grayDisabled;
        border-color: $grayDisabled;
    }
}
