@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.container {
    z-index: 2000;
    position: fixed;
    left: 50%;
    transform: translate(calc(-50% - 20px), 20px);
    width: calc(100% - 40px);
    margin: 0 20px;
    max-width: 900px;
    min-height: 4rem;
}

.hamburgerMenu {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        li {
            margin-bottom: 20px;
        }
    }
    @media screen and (min-width: 700px) {
        display: none !important;
    }
}

.hamburger {
    align-self: center;
    @media screen and (min-width: 700px) {
        display: none !important;
    }
}

.header {
    overflow-x: auto;
    overflow: visible;
    display: flex;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding-left: 4px;

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menuItemText {
        position: relative;
        padding: 0px 20px;
        height: 100%;
        text-align: center;
        border-radius: 5px;

        h5 {
            margin: 0;
            color: $primary-color;
            font-weight: 500;
            font-size: 0.95em;
        }
    }

    nav {
        display: flex;
    }
}

.contentContainer {
    display: flex;
}

.headerAccessories {
    display: flex;
    align-items: center;
    @media screen and (max-width: 699px) {
        display: none !important;
    }
}

.loginButton .showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.tryFreeButton .showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.dropdown {
    flex-direction: column;
}

.dropdownContainer {
    position: relative;
    display: none;
    animation: card 0.5s;
    animation-fill-mode: forwards;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    .dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 10px;
        width: 12rem;

        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        z-index: 100;
    }
    @media screen and (max-width: 700px) {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
    .dropdownItem {
        padding: 10px;
    }

    @keyframes card {
        0% {
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

.menuItemText:hover .dropdownContainer {
    display: block;
}
