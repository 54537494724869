h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
div,
li,
input,
select,
option,
span {
    color: #0f0f0f;
    // @media (prefers-color-scheme: dark) {
    //     color: $white;
    // }
}

.laufid-text-primary {
    color: #0f0f0f;
    // @media (prefers-color-scheme: dark) {
    //     color: $white;
    // }
}

.laufid-text-secondary {
    color: #595959;
    // @media (prefers-color-scheme: dark) {
    //     color: #cccccc;
    // }
}

.laufid-text-ternary {
    color: #0f0f0f;
    // @media (prefers-color-scheme: dark) {
    //     color: #999999;
    // }
}

.login-form-input {
    border-style: "solid";
    border-width: 1;
    background: $white;
    border-radius: 100;
    border-color: #ccc;
    padding-right: 25;
    padding-left: 25;

    // @media (prefers-color-scheme: dark) {
    //     border-style: "solid";
    //     border-width: 1;
    //     border-radius: 100;
    //     padding-right: 25;
    //     padding-left: 25;
    //     background-color: #0f0f0f;
    //     border-color: #444;
    // }
}

.laufid-background-primary {
    background-color: $primary-color;
    // @media (prefers-color-scheme: dark) {
    //     background-color: #0f0f0f;
    // }
}

.laufid-background {
    background-color: #fff;
    // @media (prefers-color-scheme: dark) {
    //     background-color: #0a0a0a;
    // }
}

.laufid-background-secondary {
    background-color: #fff;
    // @media (prefers-color-scheme: dark) {
    //     background-color: #151515;
    // }
}

.laufid-background-ternary {
    background: $white;
    // @media (prefers-color-scheme: dark) {
    //     background-color: #111;
    // }
}

.laufid-border {
    border-width: 1px;
    border-style: solid;
    border-color: #00000022;
    // @media (prefers-color-scheme: dark) {
    //     border-color: #444;
    // }
}
