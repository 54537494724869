$primary-color: #009448;
$secondary-color: #72bf93;

$orange-color: #fd974f;

$white: #fff;
$black: #313131;

$gray: #ccc;
$darkGray: #585858;
$grayDisabled: #babcbe;
