$imageSize: 145px;
.service-item {
    margin-bottom: 25px;
    padding: 15px;
    border-radius: 20px;
    .service-image {
        float: left;
        width: $imageSize;
        border-radius: 20px;
    }
    .service-item-description-container {
        margin-left: $imageSize + 20px;
    }
    .service-item-read-more {
    }
}
