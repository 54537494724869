.company-details {
    max-width: 900px;
    flex-grow: 1;

    .sjalfbaerni-buttons-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: 2px;
        margin-bottom: 5px;
        .sjalfbaerni-button {
            border: 1px solid $primary-color;
            padding: 3px 10px 4px 10px;
            background-color: #00944815;
            border-radius: 100px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: $primary-color;
            justify-content: center;
            align-items: center;

            &.primary {
                background-color: $primary-color;
                color: white;

                &:hover {
                    background-color: #009448dd;
                    color: white;
                    border-color: #009448dd;
                }
                &:active {
                    background-color: $primary-color;
                    color: white;
                    border-color: $primary-color;
                }
            }

            svg {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 5px;
            }
            &:hover {
                background-color: #009448aa;
                color: white;
            }
            &:active {
                background-color: $primary-color;
                color: white;
            }
        }
    }

    .cover-photo {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .company-name {
        font-size: 1.55rem;
        font-weight: 600;
    }

    .company-slogan {
        font-size: 20px;
    }

    .job-title {
        font-size: 20px;
        font-style: italic;
    }

    .company-location-group-title {
        margin: 0;
        margin-top: 50px;
        font-weight: 600;
    }

    .company-tag {
        color: white;
        padding: 5px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 30px;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .location-open-indicator {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        vertical-align: "center";

        margin-right: 10px;
        &.open {
            background-color: #4caf50;
        }
        &.closed {
            background-color: #cf0000;
        }
    }
}

.advertisement-container {
    position: sticky;
    top: 80px;
    height: 100%;
    margin-left: 20px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .advertisement-image-container {
        height: 400px;
        width: 300px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    @media screen and (max-width: 975px) {
        display: none !important;
    }
}

.large-phone-number {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
}

.bordered-container {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.05);
    border-width: 1px;
    border-style: solid;

    border-color: #00000022;

    // @media (prefers-color-scheme: dark) {
    //     border-color: #222;
    // }
    &.bordered-container-summary {
        padding: 18px;
        // border: none;
        // box-shadow: none;
        // background-color: #f3f3f3;
    }
    &.bordered-container-small-sections {
        padding-top: 8px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 5px;
        min-height: 51px;
    }

    &.bordered-container-information {
        padding: 10px 30px;
    }
    &.bordered-container-search-result {
        padding: 10px;
    }
    &.bordered-container-search-result:hover {
        background-color: #f9f9f9;
        // @media (prefers-color-scheme: dark) {
        //     background-color: #191919;
        // }
        transition: 0.1s ease-in-out;
    }
}

.marker-container-small {
    background-color: white;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: flex;
}

.marker-container {
    background-color: green;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
}
.marker {
    background-color: white;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-items: center;
    height: 30px;
    width: 30px;
    margin: auto;
    background-position: center;
    transform: translateZ(0);
}

.marker-small {
    background-color: $primary-color;

    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-items: center;
    height: 13px;
    width: 13px;
    margin: auto;
    background-position: center;
}

.mapboxgl-canvas-container {
    border-radius: 10px;
}
.map-container {
    border-radius: 10px;
    mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.leaf-side-container {
    background-color: #00936915;
    padding: 10px 30px;
    border-width: 1px;
    max-width: 275px;
    border-style: solid;
    border-color: $primary-color;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    h3,
    p,
    span {
        color: $primary-color;
    }
}

.leaf-tooltip {
    font-size: 25px;
}

.plus-tooltip {
    max-width: 300px;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(10px);
    transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(10px);
}

.audlindatorg-segment-item {
    border: 1px solid $primary-color;
    border-radius: 10px;
    padding: 5px 15px;
    font-weight: bold;
    margin-right: 10px;
    color: $primary-color;
    cursor: pointer;

    &.selected {
        background-color: $primary-color;
        color: white;
    }
}

.progress-bar {
    background-color: $primary-color;
}
