.search-menu-container {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    width: 395px;
    max-width: 85vw;
    background-color: white;
    border-radius: 30px;
    padding: 15px 15px;

    .search-menu-pill {
        border: 1px solid $primary-color;
        color: $primary-color;
        padding: 5px 15px;
        border-radius: 50px;
        margin: 0px 0px 10px 0px;
        .search-menu-pill-icon {
            margin-right: 10px;
        }
        &.selected {
            background-color: $primary-color;
            color: white;
        }
        &:hover:not(.selected) {
            background-color: rgba($primary-color, 0.13);
        }
    }

    .directions-menu-container {
        border: 1px solid $gray;
        border-radius: 10px;
        overflow: hidden;

        input {
            width: 100%;
            background-color: clear;
            background: clear;
            height: 40px;
            border: none;
            outline-width: 0;
        }
        .directions-menu-icon {
            height: 12;
            width: 12;
            color: $primary-color;
            margin: 0px 10px;
        }
        .directions-separator {
            height: 1px;
            width: 100%;
            background-color: $gray;
        }
    }
    .directions-menu-navigate-button {
        background-color: $primary-color;
        color: white;
        border-radius: 50px;
        border: none;
        padding: 5px 0px;
        margin: 10px 0px 0px 0px;
        width: 100%;
        font-weight: 600;
    }
}
