.about-us-image-container {
    position: relative;
    max-height: max(500px, 100vw);
    min-height: 300px;
    width: 100%;
}

.about-us-content-container {
    max-width: 1000px;

    & h2 {
        text-align: center;
    }
    & p {
        font-size: 1.1em;
        text-align: justify;
    }
}
.about-us-section-dark {
    background-color: #1b625711;
}
.about-us-section {
    max-width: 1300px;
    padding: 40px;
    flex-grow: 1;

    align-items: center;
    justify-content: center;

    & img {
        max-height: 500px;
        align-self: center;
    }
    & p {
        font-size: 1.1em;
        text-align: justify;
    }
}
.employee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    & img {
        justify-content: center;
        filter: grayscale(100%);
        border-radius: 50%;
        object-fit: contain;
    }
    & h3 {
        margin-top: 15px;
        text-align: center;
        font-size: 1.1em;
    }
    & p {
        text-align: center;
        color: #666;
        font-size: 0.9em;
    }
}
