@import "bootstrap/dist/css/bootstrap.css";
html {
    background-color: transparent;
}

body {
    background-color: #fff;
    color: #000;
    font-family: "Avenir Next", sans-serif;
}

// Base
@import "base/base";

// Pages
@import "pages/error";
@import "pages/home_page";
@import "pages/services";
@import "pages/company";
@import "pages/login";
@import "pages/map";
@import "pages/synileiki";
@import "pages/um-okkur";
@import "pages/vottanir";
@import "pages/leidin_ad_laufinu";
@import "pages/front_page";

// Components
@import "components/clickable_pill";
@import "components/page";
@import "components/side-menu";
@import "components/side_bar";
@import "components/company_info";
@import "components/footer";
@import "components/close_button";
@import "components/reset_verify";
@import "components/video_preview";
@import "components/search_results_cell";
@import "components/item_summary";
@import "components/horizontal_ad";

// .carousel-control-prev-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff077' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
// }

// .carousel-control-next-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff077' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
// }
