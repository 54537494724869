@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.container {
    z-index: 100;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    color: black;
    // padding-bottom: 80px;

    nav {
        display: flex;
        padding-top: 50px;
        padding-bottom: 35px;

        width: 100%;
        align-self: center;
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;

        @media (max-width: 550px) {
            flex-direction: column;
        }
        div {
            flex-grow: 1;
        }
        h5 {
            color: $black;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        ul {
            list-style-type: none; /* Remove bullets */
            padding: 0;
            li {
                font-size: 16px;
                color: black;

                padding: 5px 0px;
                white-space: nowrap;
            }
        }
    }
}

.copyright {
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #999;
    font-size: 14px;
    width: 100%;
}

.contentContainer {
    max-width: $max-content-width;
    flex-grow: 1;
    gap: 20px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $black;
}

.footerBottom {
    max-width: $max-content-width;
    width: 100%;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
}

.socialContainer {
    display: flex;
    gap: 10px;
}

.socialIcon {
    height: 30px;
    width: 30px;
}
