.element-container {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    &:hover {
        background-color: rgba($primary-color, 0.13);
        transition: 0.3s;
    }
    &.selected {
        background-color: rgba($primary-color, 0.87);
        transition: 0.3s;
        color: white;
    }
}

.element-title {
    padding: 5px;
    margin: 0px;
    margin-left: 5px;
    font-weight: 600;
    color: inherit;
}

.element-image {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.element-children-container {
    border-top-width: 1px;
    border-top-color: #ccc;
    // @media (prefers-color-scheme: dark) {
    //     border-top-color: #333;
    // }
    border-top-style: solid;
}

.element-child-container {
    &:hover {
        background-color: rgba($primary-color, 0.13);
        transition: 0.3s;
    }
    &.selected {
        background-color: rgba($primary-color, 0.87);
        transition: 0.3s;
        color: white;
    }
}

.element-child-container {
    border-bottom-color: #ccc;
    // @media (prefers-color-scheme: dark) {
    //     border-bottom-color: #333;
    // }
}

.element-child-image {
    width: 23px;
    margin-left: 5px;
    margin-right: 10px;
}
