.cookie-container {
    box-shadow: 0px 3px 14.2px rgba(0, 0, 0, 0.074);
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: $primary-color;
    border-radius: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 80px;
    padding-left: 20px;
    font-size: 17px;
    flex: 1;
    @media (max-width: 575px) {
        border-radius: 0px;
        margin-bottom: 0px;
        padding-top: 10px;
        padding-bottom: 80px;
    }
}

.cookie-content {
    color: white;
    flex: 1;
}

.cookie-link {
    color: white;
    text-decoration: underline;
    padding-left: 10px;
    &:hover {
        color: #aaa;
    }
}

.cookie-button {
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: black;
    cursor: pointer;
    padding: 5px 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: white;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    padding-right: 20px;
    padding-left: 20px;
    flex-grow: 1;
}
