.front-page-leaf-container {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;

    & .laufid-leaf {
        height: 35px;
        margin-right: 8px;

        @media (max-width: 475px) {
            margin-right: 10px;
        }
    }
}
