@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    margin-top: max(10vh, 150px);

    margin-bottom: 10vh;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    h1 {
        text-align: center;
        padding-bottom: 1vh;
        font-weight: 700;
    }
    h5 {
        text-align: center;
        padding-bottom: 5vh;
        font-weight: 700;
        font-size: 18px;
    }
    img {
        max-width: min(500px, 90vw);
        align-self: center;
        max-height: 40vh;
        margin-bottom: 30px;
    }
}
