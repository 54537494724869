@import "../../styles/base/variables.scss";
@import "../../styles/base/colors.scss";

.container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;

    input {
        background-color: #f3f3f3;
        font-size: 25px;
        padding-right: 100px;
        padding-left: 0.8em;
        height: 2em;

        border-radius: 1000px;
        border-style: none;
        border-width: 1px;
        width: 100%;

        flex-grow: 1;
        justify-content: center;
        align-self: center;
        vertical-align: center;

        &:focus {
            outline: none;
            box-shadow: 0 0 5px $primary-color !important;
            transition: opacity 0.3s ease-in-out;
        }

        @media screen and (max-width: 400px) {
            padding-right: 20px;
        }

        &.small {
            font-size: 20px;
            padding-right: 15px;
            padding-left: 15px;
            height: 40px;
            background-color: white;
            border: 0.5px solid #ccc;
            box-shadow: 0 0 10px #0000000f;
        }
    }

    .accessoriesContainer {
        display: flex;
        position: absolute;
        flex: 1;
        flex-direction: row;
        right: 10px;
        padding: 0px;
        margin: 0px;

        .clearIcon {
            padding-right: 5px;
            padding-left: 5px;
            justify-self: center;
            align-self: center;
            cursor: pointer;
            height: 15px;
        }
        .searchIcon {
            height: 1.5em;
            width: 1.5em;
        }

        button {
            // @media screen and (max-width: 400px) {
            //     display: none !important;
            // }
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            margin-top: -0.1em;

            padding-right: 1em;
            padding-left: 1em;

            &.small {
                // display: none;
                padding-right: 0.5em;
                padding-left: 0.5em;
                .searchIcon {
                    height: 1.2rem;
                    width: 1.2rem;
                }
            }
        }
    }
}
