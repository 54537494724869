.leidin-ad-laufinu-issuu-embed {
    position: relative;
    flex-grow: 1;
    align-items: stretch;
    border: none;
    width: 100%;
    height: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
