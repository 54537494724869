.certificate-page {
    h2 {
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 20;
        color: $primary-color;
        padding-left: 10px;
        margin-top: 20px;
    }
}

.certificate-details {
    padding: 20px !important;
    .content-container {
        picture {
            width: 100px;
            height: 100px;
            margin-right: 20px;
        }
        .details-container {
            width: 100%;
            h3 {
                font-weight: bold;
            }
            p {
                font-size: 1.4em;
            }
            .more-info-link {
                font-size: 1.5em;
                font-weight: bold;
                color: $primary-color;
            }
        }

        @media (max-width: 420px) {
            flex-direction: column;
            align-items: center;
            gap: 20px;
            picture {
                margin-right: 0px !important;
            }
        }
    }
}
