@import "../../styles/base/colors.scss";

.container {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: $primary-color;
    color: white;
    font-weight: 400;
    border-radius: 100px;
    text-align: center;
    display: inline-block;
    &:hover {
        color: $white;
        // background-color: #017d3d;
    }
    &:active {
        color: $white;
        background-color: #017d3d;
    }
    &:disabled {
        background-color: #8abda2;
    }
}

.large {
    background-color: $primary-color;
    font-size: 24px;
    line-height: 33px;
    padding: 12px 39px;
}

.medium {
    background-color: $primary-color;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 27px;
}

.small {
    background-color: $primary-color;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 21px;
}
