$aside-transition-duration: 0.2s;
.page-blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    z-index: 100;
    display: none;
    animation: fadeout 0.5s forwards;

    &.block {
        display: block;
        animation: fadein 0.5s forwards;
    }
}

.side-menu {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    max-width: 500px;
    width: min(400px, 100vw);
    animation: fadeout 0.3s;

    z-index: 3000;
    transition: 0.5s right cubic-bezier(0, 1, 0.5, 1);

    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
    overflow-y: scroll;

    &.show-menu {
        right: -400px;
    }
    .menu-items {
        ul {
            list-style: none;
            padding: 0;
        }
        width: 100%;
        margin-top: 20px;
        min-width: 150px;
    }
    .menu-row {
        font-size: 25px;
        font-weight: 600;
        color: white;
        display: block;
        transition: 0.3s;

        margin: 5px 0px;
        padding: 3px 20px;
        border-radius: 10px;

        &:hover {
            background-color: rgba($secondary-color, 0.3);
        }

        &.selected {
            background-color: $secondary-color;
        }
    }
}

.side-menu-show-small {
    @media screen and (min-width: 451px) {
        display: none !important;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 0.3;
        visibility: visible;
    }
}

@keyframes fadeout {
    from {
        opacity: 0.3;
        visibility: visible;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}
