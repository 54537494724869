@import "../../styles/base/variables.scss";

.parentContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    align-items: center;
    width: 100%;
    max-width: 100vw;
}
.container {
    max-width: $max-content-width;
    width: 100%;
}
